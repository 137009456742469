import reduxReducersMenu from "./Menu";

const INIT_STATE = false

const reduxReducerLoading = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SETLOADING": {
            return action.payload;
        }

        default:
            return state;
    }
};

export default reduxReducerLoading;