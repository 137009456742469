import API from '../util/Api';
import { Cookies } from 'react-cookie'
import { setAccessToken } from "../util/Utility";
const cookies = new Cookies();

export const GetAPIkeyCloak = async () => {//----------------------------------------ดึงข้อมูลtokenจากKeyKlock
  // let gettoken = JSON.parse(localStorage.getItem('KEYCLOAK'));
  let gettoken = cookies.get('token');
  let succesdata;
  console.log('gettoken :>> ', gettoken);

  let GETKEYCLOAK = async () => {
    try {
      const getapi = () => API.post(`${process.env.NEXT_PUBLIC_APIURL}/login`, {
        username: "devfik",
        password: "12345678",
      })
      succesdata = await getapi();
      let gettokenkeyklock = await getapi().then((data) => data)
      await cookies.set('token', gettokenkeyklock);
      await setAccessToken(gettokenkeyklock.token);
      return succesdata;
    } catch (error) {
      return error;
    }

  }

  if (gettoken !== null && gettoken !== undefined) {
    let checktokentime = new Date() > new Date(new Date(gettoken?.time_stamps?.date).getTime() + gettoken.exp * 1000).getTime()
    // console.log('checktokentime :>> ', checktokentime);
    if (checktokentime) {
      // GETKEYCLOAK()
    } else {
      setAccessToken(gettoken.token);
      succesdata = gettoken;
    }
  } else {
    // GETKEYCLOAK()
  }

  return succesdata;
}