export const SET_KEY_CLOAK = (data) => {
    return (dispatch) => dispatch({
        type: "SET_KEYCLOAK",
        payload: data
    })
}
export const SET_OAUTH = (data) => {
    return (dispatch) => dispatch({
        type: "SET_OAUTH",
        payload: data
    })
}
export const SET_GROUPS = (data) => {
    return (dispatch) => dispatch({
        type: "SET_GROUPS",
        payload: data
    })
}
export const SET_USERAUTH = (data) => {
    return (dispatch) => dispatch({
        type: "SET_USERAUTH",
        payload: data
    })

}