
import Api from './Api'
import { Cookies } from 'react-cookie'

const refreshToken = async () => {
    try {
        const cookies = new Cookies();
        const refresh_token = cookies.get("refresh_token");
        if (refresh_token) {
            const { data } = await Api.post(`/token/access_token`, { refresh_token })
            cookies.remove("access_token", { path: '/' });
            console.log('data', data)
            if (data.status === 'failed') {
                logout()
                return null
            } else {
                if (data.data.access_token) {
                    cookies.set("access_token", data.data.access_token, { path: "/" });
                    return data.data.access_token
                } else {
                    logout()
                    return null
                }
            }
        } else {
            removeCookieUserAuth()
        }
    } catch (error) {
        logout()
        return error
    }

};

const logout = () => {
    const cookies = new Cookies();
    const token = cookies.get("access_token");
    if (token && (token != undefined && token != "undefined")) {
        Api.get('/logout').then(async (data) => {
            removeCookieUserAuth()
        }).catch((eror) => {
            console.log(`eror`, eror)
            removeCookieUserAuth()
        })
    } else {
        removeCookieUserAuth()
    }
};

const removeCookieUserAuth = () => {
    const cookies = new Cookies();
    cookies.remove("access_token", { path: '/' });
    cookies.remove("refresh_token", { path: '/' });
    location.reload()
}



const apiPost = (url, data) => {
    return new Promise((resolve, reject) => {
        Api.post(url, data).then((res) => {
            resolve(res);
        }).catch((eror) => {
            refreshToken().then((res) => {
                Api.post(url, data).then((res) => {
                    resolve(res);
                }).catch((eror) => {
                    reject(eror)
                })
            }).catch((eror) => {
                logout()
                reject(eror)
            })
        })
    });
}

const apiGet = (url, data) => {
    return new Promise((resolve, reject) => {
        Api.get(url, data).then((res) => {
            resolve(res);
        }).catch((eror) => {
            refreshToken().then((res) => {
                Api.get(url, data).then((res) => {
                    resolve(res);
                }).catch((eror) => {
                    reject(eror)
                })
            }).catch((eror) => {
                logout()
                reject(eror)
            })
        })
    });
}

const apiPut = (url, data) => {
    return new Promise((resolve, reject) => {
        Api.put(url, data).then((res) => {
            resolve(res);
        }).catch((eror) => {
            refreshToken().then((res) => {
                Api.put(url, data).then((res) => {
                    resolve(res);
                }).catch((eror) => {
                    reject(eror)
                })
            }).catch((eror) => {
                logout()
                reject(eror)
            })
        })
    });
}

export default {
    post: apiPost,
    get: apiGet,
    put: apiPut,
}