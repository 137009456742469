import { combineReducers } from "redux";
import Auth from "./Auth";
import Menu from "./Menu";
import Permission from "./Permission";
import Loading from './Loading';
import LoadingLine from './LoadingLine';


const reducers = combineReducers({
  auth: Auth,
  menu: Menu,
  loading: Loading,
  loadingLine: LoadingLine,
  permission: Permission
});

export default reducers;