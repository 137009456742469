import React, { useEffect } from "react";
import { useAuthToken } from "./AppHooks";
import PropTypes from "prop-types";
import { Cookies } from 'react-cookie';
import { setAccessToken } from '../util/Utility';
import { Modal } from 'antd';
import Lottie from 'react-lottie';
import * as animationData from '../public/loadding.json';
import { useSelector } from 'react-redux';

const AuthRoutes = ({ children }) => {
	const loading = useSelector(state => state.loading);
	// useAuthToken();

	return <>
		{children}
		<Modal modalRender={() => <Lottie options={{
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice'
			}
		}}
			height={100}
			width={100}
		/>}
			centered visible={loading} closable={false} footer={null} wrapClassName={"bodyloading"}>

		</Modal>
	</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
	children: PropTypes.node.isRequired
};
