const INIT_STATE = true

const reduxReducerLoading = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SETLOADINGLINE": {
            return action.payload;
        }

        default:
            return state;
    }
};

export default reduxReducerLoading;