import reduxReducersMenu from "./Menu";

const INIT_STATE = {
    keycloak: null, userAuth: {}, groups: null,
};

const reduxReducersAuth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SET_KEYCLOAK": {
            return {
                ...state, keycloak: action.payload,
            };
        }
        case "SET_OAUTH": {
            return {
                ...state, oauth: action.payload,
            };
        }
        case "SET_USERAUTH": {
            return {
                ...state, userAuth: action.payload,
            };
        }
        case "SET_GROUPS": {
            return {
                ...state, groups: action.payload,
            };
        }

        default:
            return state;
    }
};


export default reduxReducersAuth;