const INIT_STATE = false;

const reduxReducersMenu = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SETMENU": {
            return action.payload;
        }

        default:
            return state;
    }
};

export default reduxReducersMenu;